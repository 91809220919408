/* App.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 250px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.sidebar-item span {
  margin-left: 10px;
}

.content {
  margin-left: 250px;
  flex: 1;
  padding: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  overflow-y: auto;
}

.chat-container {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  height: calc(100vh - 60px); /* Ajuste para altura do rodapé */
}

.chat-message {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
}

.contact-form .form-control {
  margin-bottom: 10px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.footer {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: calc(100% - 250px); /* Ajustado para largura da barra lateral */
  text-align: center;
  color: #fff;
  opacity: 0.5;
}

.header {
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.logo-small {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.header-text {
  color: #fff;
  font-size: 1.5rem;
}
